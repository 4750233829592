import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Camera with Intercom",
  "path": "/Frequently_Asked_Question/Two-way_Communication/",
  "dateChanged": "2018-03-15",
  "author": "Mike Polinowski",
  "excerpt": "I am looking for a camera with intercom function.",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='FAQs - Camera with Intercom' dateChanged='2018-03-15' author='Mike Polinowski' tag='INSTAR IP Camera' description='I am looking for a camera with intercom function.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Two-way_Communication/' locationFR='/fr/Frequently_Asked_Question/Two-way_Communication/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "camera-with-intercom",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#camera-with-intercom",
        "aria-label": "camera with intercom permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Camera with Intercom`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I am looking for a camera with intercom function.`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: I am looking for a camera that I can communicate with in my language. Unfortunately, I can not see so well in the Instar product descriptions whether the cameras are equipped with both a microphone and a loudspeaker and whether the Instar-SW or e.g. the Synology SW is usable for speech. Furthermore, unfortunately, it is not so obvious whether the NW cable is sufficient for audio or other connections are required. Which Instar products are suitable?`}</p>
    <p>{`Since all our indoor cameras would be suitable - I would recommend the `}<a parentName="p" {...{
        "href": "/en/Indoor_Cameras/IN-6012_HD/"
      }}>{`IN-6012 HD`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Indoor_Cameras/IN-6014_HD/"
      }}>{`IN-6014 HD`}</a>{` or `}<a parentName="p" {...{
        "href": "/en/Indoor_Cameras/IN-8015_HD/"
      }}>{`IN-8015 FHD`}</a>{`. All of these models have both a speaker and a microphone installed.`}</p>
    <p>{`This can be controlled via our free smartphone app for `}<a parentName="p" {...{
        "href": "/en/Software/Android/InstarVision/"
      }}>{`Android`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/iOS/InstarVision/"
      }}>{`iOS`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Metro/"
      }}>{`Windows`}</a>{` or `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Windows_Phone/"
      }}>{`Windows Phone`}</a>{`. Please note that the quality of the recording is designed as an intercom system. That you can communicate very well with a person who is in front of the camera. However, it is not possible to capture an entire room with it.`}</p>
    <p>{`All communication is done over the network - you do not need any additional cables (besides the patch cable).`}</p>
    <p>{`For outdoor cameras, the intercom function is also possible, but you would have to connect an external microphone and an active speaker.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      